<template>
    <div class="content-wrapper">
        <!-- <div v-if="loader" class="page_loader"></div> -->
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">All Issue Order</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchOrder">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="contact">Contact</label>
                                                <input type="text" id="contact" v-model="search.contact" placeholder="Name, Mobile, Address" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">                                            
                                                <label for="sku">Order ID</label>
                                                <input type="text" id="sku" v-model="search.sku" placeholder="Order ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">                                            
                                                <label for="ref_id">Ref. ID</label>
                                                <input type="text" id="ref_id" v-model="search.ref_id" placeholder="Reference ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="rider_id">Rider</label>
                                                <v-select name="rider_id"
                                                    v-model="search.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="area_id">Area</label>
                                                <v-select name="area_id"
                                                    v-model="search.area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.areaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="merchant_id">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="date_parameter_id">Date Parameter</label>
                                                <v-select name="date_parameter_id"
                                                    v-model="search.date_parameter_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= customState.dateParameterList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="date_type_id">Date</label>
                                                <v-select name="date_type_id"
                                                    v-model="search.date_type_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= customState.dateTypeList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="source_hub_id">Source Hub</label>
                                                <v-select name="source_hub_id"
                                                    v-model="search.source_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="destination_hub_id">Destination Hub</label>
                                                <v-select name="destination_hub_id"
                                                    v-model="search.destination_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="payment_status">Payment</label>
                                                <select id="payment_status" v-model="search.payment_status" class="form-control">                                            
                                                    <option :value="4">Select</option>
                                                    <option :value="1">Due</option>
                                                    <option :value="0">Unpaid</option>
                                                    <option :value="3">Paid </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-1">
                                            <div class="form-group">     
                                                <a style="margin-top:30px;" class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/order?start_date='+search.start_date+'&end_date='+search.end_date">
                                                    <i class="fa fa-download"></i> Excel
                                                </a> 
                                            </div>
                                        </div> -->
                                    </div>
                                </form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group" style="margin-bottom:0px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> Filter</button>                                              
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">                                
                                <div class="table-responsive">
                                    <div style="width:100%; overflow-x:auto;">
                                        <table id="orderTable" class="table table-bordered table-sm" style="width:2000px">
                                            <thead>
                                                <tr class="text-center">
                                                    <th style="width:13%">Order ID</th>
                                                    <th style="width:8%">Merchant</th>
                                                    <th style="width:8%">Customer</th>
                                                    <th style="width:10%">Address</th>
                                                    <th style="width:8%">Status</th>  
                                                    <th style="width:6%">Source Hub</th>
                                                    <th style="width:6%">Destination Hub</th>
                                                    <th style="width:6%">Order Date</th>
                                                    <th style="width:6%">Instruction</th>
                                                    <th style="width:4%">Price</th>
                                                    <th style="width:5%">Collected</th>
                                                    <th style="width:8%">Rider</th>     
                                                    <th style="width:6%">Payment</th>     
                                                    <th style="width:6%">Payment At</th>     
                                                    <th style="width:6%">Delivery Charge</th>     
                                                    <th style="width:4%">COD</th>     
                                                    <th style="width:4%">Return Charge</th>     
                                                    <th style="width:6%">Last Update</th>     
                                                    <th style="width:4%">Age (days)</th>     
                                                    <th style="width:6%">Number of Attempt</th>     
                                                    <th style="width:5%">Action</th>                                       
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order,key) in orders" :key="key">
                                                    <td style="width:13%" class="order-id">{{ order.id }}</td>
                                                    <td style="width:8%" @click="editModal(key, order)">{{ order.merchant.business }}</td>
                                                    <td style="width:8%">{{ order.name }}, {{ order.mobile }}{{ order.mobile_2 ? ', '+ order.mobile_2 : '' }}</td>
                                                    <td style="width:10%">{{ order.address }}</td>
                                                    <td style="width:8%" class="text-center"><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>                                    
                                                    <td style="width:6%" class="text-center">{{ order.source ? order.source.name : '' }}</td>
                                                    <td style="width:6%" class="text-center">{{ order.destination ? order.destination.name : '' }}</td>
                                                    <td style="width:6%">{{ order.date }}</td>
                                                    <td style="width:6%">{{ order.instruction }}</td>
                                                    <td style="width:4%" class="text-center">{{ order.price }}</td>
                                                    <td style="width:5%" class="text-center">{{ order.collected }}</td>
                                                    <td style="width:8%">{{ order.rider ? (order.rider.name +', '+ order.rider.mobile) : '' }}</td>
                                                    <td style="width:6%" class="text-center"><span :class="'payment-status-'+order.payment_status">{{ order.payment_status != 0 ? getPaymentStatus(order.payment_status) : '' }}</span></td>                                         
                                                    <td style="width:6%" class="text-center">
                                                         <slot v-if="order.payment_status == 3">
                                                            {{ order.updated_at | dateformat }} at {{ order.updated_at | timeformat }}
                                                        </slot>    
                                                    </td>                                      
                                                    <td style="width:6%" class="text-center">{{ order.service_charge }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.collected != null ? order.cod : '' }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.return_charge }}</td>                                      
                                                    <td style="width:6%">{{ order.updated_at | dateformat }} at {{ order.updated_at | timeformat }}</td>                                      
                                                    <td style="width:4%" class="text-center">{{ order.age }}</td>                                      
                                                    <td style="width:6%" class="text-center">{{ order.attempt }}</td>     
                                                    <td style="width:5%" class="text-center">
                                                        <button class="btn btn-warning btn-sm" @click="showIssue(order)" title="Issue"><i class="fas fa-question"></i></button>
                                                        <!-- <button class="btn btn-warning btn-sm" @click="issueModal.show()" title="Issue"><i class="fas fa-question"></i></button> -->
                                                    </td>
                                                </tr>
                                                <infineLoading v-if="spinner" @distance="1" @infinite="loadData"></infineLoading>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div ref="orderIssueModal" class="modal fade" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <Issue :issueModal="issueModal" :issue="issue"></Issue>
        </div>

        <div v-if="orderEditModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Details & Edit</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="heading1" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-controls="collapseOne">
                                            Edit
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordion">
                                    <div class="card-body">
                                        <b-overlay :show="loading">
                                            <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(updateForm)">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="merchant_id"
                                                                        v-model="form_edit.merchant_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.merchantList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Customer Name" required />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}" rules="min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile_2">Alternatvie Mobile</label>
                                                                    <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="coverage_area_id">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="coverage_area_id"
                                                                        v-model="form_edit.coverage_area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.coverageAreaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="division_id"
                                                                        v-model="form_edit.division_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.divisionList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="district_id"
                                                                        v-model="form_edit.district_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= districtList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="thana_id"
                                                                        v-model="form_edit.thana_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= thanaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Zone" vid="zone_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="zone_id">Zone <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="zone_id"
                                                                        v-model="form_edit.zone_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= zoneList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> -->
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="area_id"
                                                                        v-model="form_edit.area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= areaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="category_id"
                                                                        v-model="form_edit.category_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= customState.categoryList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="form_edit.status == 7">
                                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="rider_id">Rider <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="rider_id"
                                                                        v-model="form_edit.rider_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= riderList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="instruction">Instruction (Note)</label>
                                                                    <input type="text" id="instruction" v-model="form_edit.instruction" class="form-control" placeholder="Instruction (Note)" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="ref_id">Reference ID</label>
                                                                    <input type="text" id="ref_id" v-model="form_edit.ref_id" class="form-control" placeholder="Reference ID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Amount to Collected" vid="price" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Amount to Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="price" v-model="form_edit.price" :readonly="form_edit.payment_status > 0 ? true : false" class="form-control" placeholder="Amount to Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="form_edit.status == 12 || form_edit.status == 14 || form_edit.status == 15 || form_edit.status == 16 || form_edit.status == 19 || form_edit.status == 20">
                                                            <ValidationProvider name="Collected" vid="collected" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="collected" v-model="form_edit.collected" @input="checkCollectedAmount" class="form-control" placeholder="Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="exchange"
                                                                        v-model="form_edit.exchange"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= exchangeList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Status" vid="status" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="name">Status</label>
                                                                    <select id="status" v-model="form_edit.status" @change="statusChange($event)"  class="form-control">                                            
                                                                        <option v-for="(status,index) in mappingStatusList" :key="index" :value="status.id">{{ status.text }}</option>
                                                                    </select>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> 
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isRescheduleDate">
                                                             <ValidationProvider name="Reschedule Date" vid="reschedule_date" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="reschedule_date">Reschedule Date <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="date" v-model="form_edit.reschedule_date" class="form-control" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                             </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="isReason">
                                                            <ValidationProvider name="Reason" vid="reason_id" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>    
                                                                    <v-select name="reason_id"
                                                                        v-model="form_edit.reason_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.reasonList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isReasonText">
                                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason Text <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="text" v-model="form_edit.reason" class="form-control" readonly/>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div> 
                                                </form>
                                            </ValidationObserver>
                                        </b-overlay>                                     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading2" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                            Others
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse2" class="collapse show" aria-labelledby="heading2" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Order Information</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Order ID : {{ form_edit.tracking_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Reference ID : {{ form_edit.ref_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name : {{ form_edit.name }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Mobile : {{ form_edit.mobile }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Coverage Area : {{ form_edit.coverage ? form_edit.coverage.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Division : {{ form_edit.division ? form_edit.division.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>District : {{ form_edit.district ? form_edit.district.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Thana : {{ form_edit.thana ? form_edit.thana.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Area : {{ form_edit.area ? form_edit.area.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Destination : {{ form_edit.destination ? form_edit.destination.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Address : {{ form_edit.address }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Instruction : {{ form_edit.instruction }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Deadline : {{ form_edit.date }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Amount : {{ form_edit.price }}</th>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Payment Details</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Amount</th>
                                                            <td>{{ form_edit.price }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Collected </th>
                                                            <td>{{ form_edit.collected }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Service Charge</th>
                                                            <td>{{ form_edit.service_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Return Charge</th>
                                                            <td>{{ form_edit.return_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Area Charge</th>
                                                            <td>{{ form_edit.area_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Weight Charge</th>
                                                            <td>{{ form_edit.weight_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>COD Charge</th>
                                                            <td>{{ form_edit.status > 5 ? form_edit.cod : 0 }}</td>
                                                        </tr>
                                                    </table>
                                                    <button class="btn btn-success btn-sm mr-2" @click="printPos">
                                                        POS <i class="fa fa-print"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name">Order Activity</label>
                                                    <table class="table table-bordered table-sm" style="width:100%">
                                                        <tr>
                                                            <th style="width:20%">Action By</th>
                                                            <th style="width:55%">Action</th>
                                                            <th style="width:25%">Date & Time</th>
                                                        </tr>
                                                        <tr v-for="(log, index) in form_edit.logs" :key="index">
                                                            <td>{{ log.operation_by }}</td>
                                                            <td>{{ log.operation }}</td>
                                                            <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import statusHelper from '@/utils/statusMapping'
    import Pos from './Pos.js'
    import ExportPdf from './Order.js'
    import infineLoading from 'vue-infinite-loading'
    import config from '@/config'  
    import moment from 'moment'
    import { Modal } from 'bootstrap'
    import Issue from './Issue.vue'
    // import Summary from './Summary.vue'
    const excelColumn = {
        'Order ID': 'order_id',
        'Tracking ID': 'order_tracking_id',
        'SKU': 'order_sku',
        'Merchant': 'order_merchant_name',
        'Reference ID': 'order_ref_id',
        'Name': 'order_contact_name',
        'Number': 'order_contact_number',
        'Address': 'order_address',
        'Order Date': 'order_date',
        'Assign Date': 'order_assign_date',
        'Amount': 'order_amount',
        'Collected Amount': 'order_collected',
        'Rider': 'order_rider_name',
        'Status': 'order_status'
    }
    export default {
        name:'Order',
        components: {
            infineLoading: infineLoading,
            Issue: Issue
        },
        data () {
            return {
                exchangeList: [
                    { id: 1, text: 'Yes' },
                    { id: 2, text: 'NO' },
                ],
                isExcelDownload: false,
                summary: null,
                spinner: true,
                loader: true, 
                loading: false,
                isScan: false,
                isSelected: true,
                selected: [],
                allSelected: false,
                ordersData: [],
                zones:[],
                orders:[],
                totalOrder: 0,
                order_id: '',
                printorders:[],
                barcode:{
                    sku: ''
                },
                orderList: [],
                page: 1,
                search: {
                    isSearch : 0,
                    contact : '',
                    sku : '',
                    ref_id: '',
                    merchant_id : '',
                    rider_id    : '',
                    area_id : '',
                    // zone_id        : 0,
                    payment_status : 4,
                    head_status : '',
                    status : '',
                    date_parameter_id : 4,
                    date_type_id : 2,
                    source_hub_id : '',
                    destination_hub_id : '',
                    start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                },
                form_edit:'',
                errors:[],
                createModal: 0,
                issueModal: 0,
                issue: '',
                orderEditModal:false,
                deleteModal:false,
                status:{
                    key: '',
                    sku: '',
                    status: '',
                    collected: 0,
                    reason:' ',
                    reason_id: 0,
                },
                isRescheduleDate: false,
                isReason: false,
                isReasonText: false,
                areaList: [],
                totalSelected: 0,
                takeAction: true,
                key:'',
                mappingStatusList: []
            }
        },
        watch: {
            stateReload: function (newVal) {
                if (newVal) {
                    this.searchOrder()
                }
            },
            'search.date_type_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                   this.dateTypeChange(newVal)
                }
            },
            'search.head_status': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.search.status = this.getCustomSearchStatus(newVal)
                }
            },
            'form_edit.division_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.districtList = this.getDistrictList(newVal)
                }
            },
            'form_edit.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'search.destination_hub_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.areaList = this.getHubAreaList(newVal)
                }
            },
            'form_edit.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    // this.zoneList = this.getZoneList(newVal)
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form_edit.weight': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.form_edit.weight_charge = this.getWeightCharge(newVal)
                }
            },
            '$route.query': function (newVal, oldVal) {
                if(newVal != oldVal) {
                    Object.assign(this.search, { sku: this.$route.query.id })
                    this.loadData()
                }
            }
        },
        created () {
            if (this.$route.query) {
                Object.assign(this.search, { sku: this.$route.query.id })
            } 
            this.searchOrder();
        },
        mounted() {
            this.issueModal = new Modal(this.$refs.orderIssueModal)
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            customState () {
                return this.$store.state
            },
            userStatusList () {
                return this.$store.state.userStatusList
            },
            authUser () {
                return this.$store.state.authUser
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            riderList () {
                if (this.authUser.role_id == 7) {
                    return this.commonObj.riderList.filter(el => el.hub_id == this.authUser.hub_id)
                } else {
                    return this.commonObj.riderList
                }
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                if (this.isExcelDownload) {
                    if (this.printorders.length > 0) {
                        this.printorders.map(item => {
                            item.order_id = item.id
                            item.order_tracking_id = item.tracking_id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant.business,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_id ? item.rider.name : '',
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.printorders
                    } else if (this.orders.length > 0) {
                        this.orders.map(item => {
                            item.order_id = item.id
                            item.order_tracking_id = item.tracking_id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant.business,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_id ? item.rider.name : '',
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.orders
                    } else {
                        return []
                    }
                }
                return []
            }
        },
        methods:{
            dateTypeChange (newVal) {
                const dateTypeId = newVal
                if (dateTypeId == 1) {
                    this.search.start_date = this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 2) {
                    this.search.start_date = this.search.end_date = moment().subtract(1,'d').format('YYYY-MM-DD')
                } else if (dateTypeId == 3) {
                    this.search.start_date = moment().subtract(31,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 4) {
                    this.search.start_date = moment().subtract(180,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 5) {
                    this.search.start_date = moment().subtract(365,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                }
            },
            reload () {
                this.commonStatus = []
                this.search = Object.assign({}, {
                    isSearch    : 0,
                    contact     : '',
                    sku         : '',
                    ref_id: '',
                    merchant_id : '',
                    rider_id    : '',
                    // zone_id     : 0,
                    area_id     : '',
                    payment_status : 4,
                    head_status : '',
                    status      : '',
                    date_parameter_id  : 2,
                    date_type_id  : 2,
                    source_hub_id  : '',
                    destination_hub_id  : this.authUser.role_id == 7 ? this.authUser.hub_id : '',
                    start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                })
                this.orderList = this.ordersData = this.printorders = []
                this.totalSelected = 0
            },
            actionPanelOrderToPdf() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.exportPdfDetails(base64Logo, this.printorders)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            getStatus (status) {
                const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
                return tmpStatus.text
            },
            getPaymentStatus (paymentStatus) {
                if (paymentStatus === 1) {
                    return 'Due'
                } else if (paymentStatus === 2) {
                    return 'Processing'
                } else if (paymentStatus === 3) {
                    return 'Paid'
                }
            },
            getCustomSearchStatus (headStatus) {
                let searchAbleStatusList = []
                if (headStatus == 1) {
                    searchAbleStatusList = [14,15,16,19,20,24,25,26,31]
                } else if (headStatus == 2) {
                    searchAbleStatusList = [1,3,4,5,6,7,9,10,11,12,13,17,18,21,22,23]
                }
                return searchAbleStatusList
            },
            searchOrder() {
                this.search.isSearch = 1
                this.totalOrder = 0
                this.orders = []
                this.page = 1
                this.search.destination_hub_id = this.authUser.role_id == 7 ? this.authUser.hub_id : this.search.destination_hub_id
                if ( this.authUser.role_id > 2 && this.search.status ) {
                    this.mappingStatusList = statusHelper.getMappingStatus(this.search.status[0])
                } else {
                    this.mappingStatusList = statusHelper.getMappingStatus(0)
                }
                this.loadData()
            },
            loadData($state) {
                // this.$store.dispatch('summarySearch', true)
                this.loader = false
                let vm = this;
                const params = Object.assign({}, this.search, { page: this.page })
                config.getData('order-issue/list', params)
                .then(res => {
                    return res
                }).then(response => {
                    // this.summary = response.summary
                    this.totalOrder += response.data.data.length
                    response.data.data.map(item => {
                        const tmpItem = this.getRelationalData(item)
                        vm.orders.push(tmpItem)
                    })
                    if (this.totalOrder < response.data.total) {
                        this.spinner = true
                        $state.loaded()
                    } else {
                        this.spinner = false
                    }
                });
                
                this.page = this.page + 1;
            },
            getRelationalData (item) {
                const startDate = moment(item.date).format("YYYY-MM-DD")
                const todayDate = moment().startOf('day')
                const newAge = item.status < 14 ? moment.duration(todayDate.diff(startDate)).asDays() : '';
                return Object.assign(item, {
                    age: newAge
                })
            },
            cancelModal(){                
                this.orderEditModal = this.deleteModal = false
                this.isReason = this.isReasonText = this.isRescheduleDate = this.isScan = this.isExcelDownload = false
                this.allSelected = false
                this.barcode.sku = ''
                this.totalSelected = 0
                this.printorders = []
                this.ordersData = []
                this.orderList = []   
                this.mappingStatusList = []   
                this.bulk.rider_id = this.bulk.status = this.bulk.merchant_id = this.bulk.hub_id = ''
                this.bulk.payment_status = 1
            },
            deleteOrder(key, order_id){
                this.order_id = order_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyOrder(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.deleteData(`/order/destroy/${this.order_id}/${this.authUser.role_id}`)
                this.loader = false
                if (response.status == 200) {
                    this.deleteModal = false
                    this.$toast.success('Data deleted successfully') 
                } else {
                    this.$toast.error('Data not deleted') 
                }
                this.$store.dispatch('stateReload', false)
            },
            editModal (index, order){
                this.mappingStatusList = statusHelper.getMappingStatus(order.status)
                document.body.classList.add("modal-open");
                this.isRescheduleDate = order.reschedule_date != null ? true : false
                this.isReasonText = order.reason != null ? true : false
                this.form_edit =  JSON.parse(JSON.stringify(order))
                this.printorders.push(this.form_edit)
                this.key = index
                this.orderEditModal = true  
            },
            async scanDone () {
                this.loading = true
                const response = await config.postData('/order/scaned-order', { 'order_ids': this.orderList })                        
                this.loading = false                                          
                this.isScan = false                                          
                if (response.status === 200) {                                           
                    this.printorders = response.data;
                    this.isExcelDownload = true
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Data not Found !!',
                        color: '#dc3545'
                    })
                }
            },
            printPos() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                Pos.expostPosDetails(base64Logo, this.printorders)
            },
            statusChange (event, price, collected) {
                this.isRescheduleDate = this.isReason = this.isReasonText = false
                var status = event.target.value
                if (status == 18) {
                    this.isRescheduleDate = true;
                    this.isReasonText = true
                } else if (status >= 15 && status <= 19) {
                    this.isReasonText = true
                } else if (status == 20) {
                    this.isReason = true
                } else if (price != collected) {
                    this.isReason = true
                }
            },
            getDateParameter (dateParameterId) {
                const dateParameter = this.customState.dateParameterList.find(param => param.id == dateParameterId)
                return dateParameter.text
            },
            getRiderName (riderId) {
                const rider = this.commonObj.riderList.find(rider => rider.id == riderId)
                return rider.text
            },
            getMerchantName (merchantId) {
                const merchant = this.commonObj.merchantList.find(merchant => merchant.id == merchantId)
                return merchant.text
            },
            getZones () {
                const areaId = this.form_edit.area_id
                const tmpZones = this.$store.state.commonObj.zoneList.find(tmp => tmp.area_id == areaId)
                this.zones = tmpZones
            },
            showIssue (order) {
                const tmpIssue = order.issues.slice(-1)[0]
                const tmp = this.commonObj.issueList.find(iss => iss.id == parseInt(tmpIssue.issue_id))
                this.issue = Object.assign(tmpIssue, { issue_title: tmp.text })
                this.issueModal.show()
            },
            getWeightCharge (weight) {
                const tmpCharge = this.commonObj.weightChargeList.find(el => el.id == weight)
                return typeof tmpCharge != 'undefined' ? tmpCharge.charge : 0
            },
            getCategoryName (categoryId) {
                const category = this.customState.categoryList.find(cat => cat.id === categoryId)
                return  category.text
            },
            getDistrictList (divisionId) {
                return this.commonObj.districtList.filter(el => el.division_id == divisionId)
            },
            getThanaList (districtId) {
                return this.commonObj.thanaList.filter(el => el.district_id == districtId)
            },
            getZoneList (thanaId) {
                return this.commonObj.zoneList.filter(el => el.thana_id == thanaId)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            },
            getHubAreaList (hubId) {
                return this.commonObj.areaList.filter(el => el.hub_id == hubId)
            },
            checkCollectedAmount () {
                this.isReasonText = (parseFloat(this.form_edit.price) != parseFloat(this.form_edit.collected)) ? true : false                
            }
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }
</style>