import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const exportPdfDetails = async (base64Logo, vm, startDate, endDate, riderName, merchantName, hubName) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, height: 70, width: 70, style: 'logo', alignment: 'left' },
      { text: 'Hub: '+ hubName +', Statement: '+ startDate + ' to '+ endDate +' '+ merchantName + riderName +', Total Order : ' + vm.length, style: 'org', alignment: 'left' }
    ]

    pdfContent.push({ text: '', style: 'space' })

    const allRows = [
      [
        { text: 'Barcode', style: 'th', alignment: 'center', bold: true },
        { text: 'Order Info', style: 'th', alignment: 'center', bold: true },
        { text: 'Merchant', style: 'th', alignment: 'center', bold: true },
        { text: 'Customer', style: 'th', alignment: 'center', bold: true },
        { text: 'Address', style: 'th', alignment: 'center', bold: true },
        { text: 'Area', style: 'th', alignment: 'center', bold: true },
        { text: 'Price', style: 'th', alignment: 'center', bold: true },
        { text: 'Collected', style: 'th', alignment: 'center', bold: true },
        { text: 'Status', style: 'th', alignment: 'center', bold: true },
        { text: 'Payment', style: 'th', alignment: 'center', bold: true },
        { text: 'Instruction/Reason', style: 'th', alignment: 'center', bold: true }
      ]
    ]
    
    vm.forEach(item => {
        const orderInfo = item.tracking_id + ',Date:' + item.date + ',' + (item.ref_id ? 'Ref:'+ item.ref_id : '')
        const merchant = item.merchant.business + ', ' + item.merchant.mobile
        const customer = item.name + ', ' + item.mobile
        const orderStatus = getOrderStatus(item.status)
        const paymentStatus = getPaymentStatus(item.payment_status)
        const instructionReason = (item.instruction ? item.instruction : '') + (item.reason ? '/'+item.reason : '')
        const areaRiderName = item.area.name + ', ' + (item.rider_id ? item.rider.name : '')

        allRows.push([
            { image: 'data:image/' + generateBarcode(item.id), alignment: 'center', style: 'barcodetd' },
            { text: orderInfo, alignment: 'left', style: 'td' },
            { text: merchant, alignment: 'left', style: 'td' },
            { text: customer, alignment: 'left', style: 'td' },
            { text: item.address, alignment: 'left', style: 'td' },
            { text: areaRiderName, alignment: 'left', style: 'td' },
            { text: item.price, alignment: 'center', style: 'td' },
            { text: item.collected, alignment: 'center', style: 'td' },
            { text: orderStatus, alignment: 'left', style: 'td', color: getStatusColor(item.status) },
            { text: paymentStatus, alignment: 'left', style: 'td', color: getPaymentStatusColor(item.payment_status) },
            { text: instructionReason, alignment: 'left', style: 'td' }
        ]) 
    })

    pdfContent.push({
        table: {
            headerRows: 1,
            widths: ['20%', '10%', '9%', '9%', '15%', '8%', '4%', '5%', '5%', '5%', '10%'],
            body: allRows
        }
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
                [
                  { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'left', style: 'pageNo', /*margin: [0, 0, 0, 0]*/ }
                ]
            ]
          }
        };
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      styles: {
        th: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: 8,
          margin: [0, 0, 0, 0]
        },
        barcodetd: {
          fontSize: 8,
          margin: [0, 4, 0, 0]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        logo: {
          margin: [0, -30, 0, 0]
        },
        pageNo: {
            fontSize: 8,
            margin: [10, 6, 0, 0]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    var win = window.open('', '_blank')
    pdfMake.createPdf(docDefinition).open({}, win)
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function getOrderStatus (status) {
  const tmpStatus = Store.state.statusList.find(el => el.id == status)
  return tmpStatus.text
}

function getPaymentStatus (paymentStatus) {
  if (paymentStatus == 1) {
    return 'Due'
  } else if (paymentStatus == 2) {
    return 'Processing'
  } if (paymentStatus == 3) {
    return 'Paid'
  }
}

function getStatusColor (status) {
  if (status == 1) {
    return [129, 127, 127]
  } else if (status == 2) {
    return [255,165,0]
  } else if (status == 3) {
    return [255, 0, 255]
  } else if (status == 4) {
    return [89, 165, 216]
  } else if (status == 4 || status == 5 || status == 9 || status == 17 || status == 22) {
    return [0, 0, 0]
  } else if (status == 6 || status == 7 || status == 11 || status == 21 || status == 23 || status == 25) {
    return [255, 193, 7]
  } else if (status == 14 || status == 19 || status == 27 || status == 28) {
    return [12, 207, 12]
  } else if (status == 18) {
    return [51, 51, 255]
  } else if (status == 24 || status == 26) {
    return [128,0,128]
  } else if (status == 2 || status == 12 || status == 20) {
    return [222, 11, 11]
  } else if (status == 8) {
    return [202, 88, 88]
  } else if (status == 10) {
    return [167, 179, 11]
  } else if (status == 13 || status == 15) {
    return [0, 255, 128]
  } else if (status == 16 || status == 19) {
    return [65, 94, 82]
  }
}

function getPaymentStatusColor (status) {
  if (status == 1) {
    return [129, 127, 127]
  } else if (status == 2) {
    return [255,165,0]
  } else if (status == 3) {
    return [12, 207, 12]
  }
}

function generateBarcode (orderNo) {
  let canvas = document.createElement('canvas');
  JsBarcode(canvas, orderNo, { 
      format: 'CODE39',
      displayValue: false,
      height: 16,
      width: 1,
      padding: 0,
      margin: 0
  });
  return canvas.toDataURL('image/png');
}

export default {
    exportPdfDetails
}
